import * as React from 'react';
import { SVGProps } from 'react';

const SvgCurl = (
  props: SVGProps<SVGSVGElement> & React.HTMLAttributes<HTMLElement>
) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={4} cy={8} r={1.5} fill="#a6adc2" />
    <circle cx={4} cy={14} r={1.5} fill="#a6adc2" />
    <path
      className="curl"
      d="M15 5 6.5 18.5M21 5l-8.5 13.5"
      stroke="#a6adc2"
      strokeWidth={2}
    />
  </svg>
);
export default SvgCurl;
