import * as React from 'react';
import { SVGProps } from 'react';

const SvgPhp = (
  props: SVGProps<SVGSVGElement> & React.HTMLAttributes<HTMLElement>
) => (
  <svg
    width={30}
    height={24}
    viewBox="0 0 30 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M15 14.907c7.907 0 14.318-3.337 14.318-7.454C29.318 3.337 22.908 0 15 0 7.092 0 .682 3.337.682 7.453c0 4.117 6.41 7.454 14.318 7.454Z" />
    <path
      className="fill-background"
      clipRule="evenodd"
      fillRule="evenodd"
      d="m4.702 11.866 1.57-7.943H9.9c1.57.098 2.354.882 2.354 2.255 0 2.354-1.864 3.727-3.53 3.629H6.957l-.392 2.06H4.702Zm2.648-3.53.49-2.942h1.276c.686 0 1.176.294 1.176.882-.098 1.668-.882 1.962-1.765 2.06H7.35Zm4.54 1.47 1.568-7.943h1.864l-.393 2.06h1.765c1.57.098 2.158.882 1.962 1.863l-.687 4.02h-1.96l.686-3.628c.098-.49.098-.784-.589-.784h-1.47l-.883 4.413h-1.864Zm5.954 2.06 1.569-7.943h3.628c1.57.098 2.354.882 2.354 2.255 0 2.354-1.863 3.727-3.53 3.629h-1.766l-.392 2.06h-1.863Zm2.647-3.53.49-2.942h1.276c.686 0 1.177.294 1.177.882-.098 1.668-.883 1.962-1.765 2.06h-1.177Z"
    />
  </svg>
);
export default SvgPhp;
