import { Config, Node, Tag } from '@markdoc/markdoc';
import { MarkdownComponent } from '@squareup/dex-types-shared-markdown';
import { Box } from '@squareup/dex-ui-shared-base';
import { Image } from '@squareup/dex-ui-shared-image';
import { card } from '@squareup/dex-ui-shared-markdown-card';
import clsx from 'clsx';
import React, { CSSProperties, FC, PropsWithChildren } from 'react';

import styles from './markdown-tabset.module.css';

interface MarkdownTabProps {
  id: string;
  imageUrl?: string;
  imageAlt?: string;
  imageTitle?: string;
  imageWidth?: number;
  imageHeight?: number;
}

const MarkdownTab: FC<PropsWithChildren<MarkdownTabProps>> = ({
  children,
  id,
  imageUrl,
  imageAlt,
  imageTitle,
  imageWidth,
  imageHeight,
}) => {
  return (
    <Box
      className={clsx(styles.tab, imageUrl && styles['has-image'])}
      testId={`markdown-tab-${id}`}
      margin={{ top: '3x' }}
      style={
        // Gives us the ability to set the height of the whole tab in desktop view
        imageHeight
          ? ({
              '--markdown-tab-image-height': `${imageHeight}px`,
            } as CSSProperties)
          : undefined
      }
    >
      {imageUrl ? <Box>{children}</Box> : children}
      {/* Don't show the image until we figure out the height. Otherwise you'll see it shrink */}
      {imageUrl && (
        <Box className={styles.col2}>
          <Image
            className={styles.tabImage}
            src={imageUrl}
            alt={imageAlt || 'tab-image'}
            title={imageTitle}
            fillIfNoDimensions={true}
            objectFit="contain"
            objectPosition="left top"
            {...(imageWidth &&
              imageHeight && {
                width: imageWidth,
                height: imageHeight,
              })}
          />
        </Box>
      )}
    </Box>
  );
};

const schema = {
  render: 'MarkdownTab',
  attributes: {
    id: { type: String, required: true },
    imageUrl: { type: String, required: false },
    imageAlt: { type: String, required: false },
    imageTitle: { type: String, required: false },
    imageWidth: { type: Number, required: false },
    imageHeight: { type: Number, required: false },
  },
  transform(node: Node, config: Config) {
    const attributes = node.transformAttributes(config);
    const children = node.transformChildren(config);

    // Cards within a tab are always borderless
    children
      .filter(
        (child): child is Tag =>
          Tag.isTag(child) && child.name === card.component.tagName
      )
      .forEach((child) => {
        // eslint-disable-next-line no-param-reassign
        child.attributes = {
          ...child.attributes,
          forceBorderless: true,
          paddingSize: 'none',
          inTab: true,
        };
      });

    return new Tag(this.render, attributes, children);
  },
};

const tab: MarkdownComponent = {
  tag: {
    name: 'tab',
    schema,
  },
  component: {
    tagName: schema.render,
    value: MarkdownTab,
  },
};

export { tab };
