import * as React from 'react';
import { SVGProps } from 'react';

const SvgRuby = (
  props: SVGProps<SVGSVGElement> & React.HTMLAttributes<HTMLElement>
) => (
  <svg
    width={20}
    height={19}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="m14.034 11.055-8.347 5.379 12.61 1.721-4.263-7.1Zm5.457-7.913L18.373 4.82a9967.426 9967.426 0 0 0-3.832 5.768c-.07.107-.163.192-.063.357 1.234 2.037 2.457 4.08 3.684 6.121.194.322.39.643.64.953l.744-14.86-.055-.017ZM2.653 9.492c.058.055.202.087.269.054 1.183-.581 2.374-1.15 3.531-1.78.377-.204.667-.572.993-.87 1.076-.988 2.15-1.977 3.226-2.967.066-.06.143-.12.183-.196.386-.755.765-1.512 1.157-2.293-.468-.175-.912-.348-1.361-.504-.061-.022-.157.02-.223.058-1.04.58-2.1 1.13-3.108 1.765-.504.317-.914.784-1.36 1.19-.72.657-1.438 1.315-2.147 1.983-.188.177-.36.38-.506.593-.485.706-.954 1.421-1.446 2.158.275.285.525.557.792.81Zm4.324-.964L5.2 16.04l8.355-5.383-6.577-2.13Zm4.69-4.176 2.41 5.862 4.691-7.048-7.1 1.186ZM7.076 8.02l6.446 2.093-2.385-5.807L7.076 8.02Zm-4.334 2.924L.486 16.335l4.297-.127-2.04-5.265Zm2.092 4.015.053-.012c.516-2.145 1.04-4.275 1.566-6.504l-3.447 1.791c.587 1.528 1.214 3.139 1.828 4.725ZM17.9 2.818c-.686-.181-1.372-.364-2.058-.544-.987-.26-1.974-.516-2.96-.778-.123-.033-.213-.054-.285.095-.348.714-.708 1.422-1.062 2.133-.01.023-.009.051-.017.106l6.38-.967.002-.045Zm-3.98-1.624 5.592 1.547L18.59.108l-4.66 1.034-.008.052Zm1.693 17.044-4.588-.626c-1.906-.26-3.808-.546-5.718-.766-.693-.08-1.404-.007-2.106.005-.577.01-1.154.032-1.731.05a.229.229 0 0 0-.178.078l14.31 1.323.01-.064ZM.58 14.69c.615-1.426 1.227-2.853 1.844-4.278.062-.144.058-.24-.063-.355-.259-.246-.498-.511-.78-.805L.454 14.808l.041.02c.029-.046.063-.09.084-.139ZM12.696.818C13.733.55 14.785.34 15.83.108c.068-.015.134-.038.2-.057L16.02 0l-4.492.604c.422.185.747.322 1.168.214Z" />
  </svg>
);
export default SvgRuby;
