import LinkIcon from '@square-icons/react/16/UI/link';
import { NullableClassName } from '@squareup/dex-types-shared-ui';
import { TrackedProps } from '@squareup/dex-types-shared-utils';
import { Box } from '@squareup/dex-ui-shared-base';
import { MarketTooltip } from '@squareup/dex-ui-shared-market';
import { getWindow } from '@squareup/dex-utils-environment';
import clsx from 'clsx';
import React, {
  ReactNode,
  useEffect,
  useState,
  useRef,
  AriaAttributes,
  useCallback,
  KeyboardEventHandler,
} from 'react';

import { NativeButton } from '../NativeButton';

import styles from './anchored-heading.module.css';

export type AnchoredHeadingProps = {
  children: ReactNode;
  anchor: string;
  alignment?: 'center' | 'baseline';
} & TrackedProps;

const TOOLTIP_TEXT_DEFAULT = 'Link to section';
const TOOLTIP_TEXT_COPIED = 'Copied';

const AnchoredHeading = ({
  children,
  anchor,
  trackingId,
  trackingExtra,
  className,
  alignment = 'center',
  ...rest
}: AnchoredHeadingProps & AriaAttributes & NullableClassName) => {
  const marketTooltipRef = useRef<globalThis.HTMLMarketTooltipElement>(null);

  const [tooltipText, setTooltipText] = useState(TOOLTIP_TEXT_DEFAULT);

  const resetTooltipTimeoutRef = useRef<number | null>(null);

  useEffect(() => {
    // This is a hack that forces the underlying popper instance to update.
    // Since the text shrinks, it's off-center until you actually force popper to update
    // and rerender. The `click` forces that interaction to happen, and it must happen
    // on the underlying market-dropdown
    if (tooltipText === TOOLTIP_TEXT_COPIED) {
      marketTooltipRef.current?.shadowRoot
        ?.querySelector('market-dropdown')
        ?.click();
    }
  }, [tooltipText]);

  const onTooltipClicked = useCallback(async () => {
    const win = getWindow();
    if (!win) {
      return;
    }

    await navigator.clipboard.writeText(
      `${win.location.href.replace(win.location.hash, '')}#${anchor}`
    );

    setTooltipText(TOOLTIP_TEXT_COPIED);
  }, [anchor]);

  const onKeyDown = useCallback<KeyboardEventHandler<HTMLButtonElement>>(
    (e) => {
      if (e.key === 'Enter') {
        onTooltipClicked();
      }
    },
    [onTooltipClicked]
  );

  async function onButtonFocus() {
    // Keyboard focus doesn't automatically open the tooltip, so we
    // will do it manually
    await marketTooltipRef.current?.openTooltip();
  }

  async function onButtonBlur() {
    // Keyboard blur won't automatically close the tooltip,
    // so we will do it manually.
    await marketTooltipRef.current?.closeTooltip();
  }

  function clearResetTooltipTimeout() {
    if (resetTooltipTimeoutRef.current) {
      clearTimeout(resetTooltipTimeoutRef.current);
    }

    resetTooltipTimeoutRef.current = null;
  }

  function onMarketTooltipOpened() {
    clearResetTooltipTimeout();
  }

  function onMarketTooltipClosed() {
    clearResetTooltipTimeout();

    const timer = setTimeout(() => {
      setTooltipText(TOOLTIP_TEXT_DEFAULT);
    }, 50) as unknown as number;

    resetTooltipTimeoutRef.current = timer;
  }

  return (
    <>
      <Box
        as="a"
        className={styles['anchor']}
        href={`#${anchor}`}
        id={anchor}
        aria-label={`anchor link for ${anchor}`}
      ></Box>
      <Box
        className={clsx(
          styles['heading'],
          alignment === 'baseline' && styles.baseline,
          className
        )}
      >
        <Box className={styles['container']}>
          <MarketTooltip
            className={clsx(styles.tooltip)}
            popoverPlacement="top-start"
            onMarketTooltipOpened={onMarketTooltipOpened}
            onMarketTooltipClosed={onMarketTooltipClosed}
            ref={marketTooltipRef}
            style={{
              // These need to be applied inline because applying them as a class
              // only takes affect after the first render due to how Market components
              // work with React. Otherwise, the tooltip will be too large on the first render.
              // This solves a shifting issue on a lot of our pages
              '--tooltip-minimum-width': '0px',
              '--tooltip-minimum-height': '0px',
            }}
          >
            <NativeButton
              role="link"
              as={'a'}
              tabIndex={0}
              trackingId={trackingId}
              trackingExtra={trackingExtra}
              className={clsx(styles['trigger'], styles.button)}
              slot="trigger"
              onClick={onTooltipClicked}
              onKeyDown={onKeyDown}
              onFocus={onButtonFocus}
              onBlur={onButtonBlur}
              aria-label={`click to copy link for ${anchor}`}
              {...rest}
            >
              <LinkIcon className={styles['icon']} />
            </NativeButton>
            <span slot="content">{tooltipText}</span>
          </MarketTooltip>
        </Box>
        {children}
      </Box>
    </>
  );
};

export { AnchoredHeading };
