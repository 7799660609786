import * as React from 'react';
import { SVGProps } from 'react';

const SvgNodejs = (
  props: SVGProps<SVGSVGElement> & React.HTMLAttributes<HTMLElement>
) => (
  <svg
    width={20}
    height={23}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M10 22.127c-.305 0-.59-.082-.854-.224l-2.703-1.606c-.407-.224-.204-.305-.082-.346.549-.183.65-.223 1.22-.548.06-.041.142-.02.203.02l2.074 1.24c.08.04.182.04.243 0l8.111-4.696c.081-.04.122-.122.122-.223V6.373c0-.102-.04-.183-.122-.224l-8.11-4.675c-.082-.04-.183-.04-.244 0L1.747 6.149c-.081.04-.122.142-.122.224v9.37c0 .082.04.184.122.224l2.216 1.28c1.199.61 1.951-.1 1.951-.812v-9.25c0-.121.102-.243.244-.243h1.037c.122 0 .244.101.244.244v9.249c0 1.606-.875 2.54-2.4 2.54-.467 0-.833 0-1.87-.508l-2.134-1.22a1.716 1.716 0 0 1-.853-1.483V6.393c0-.61.325-1.179.853-1.484L9.146.213a1.814 1.814 0 0 1 1.708 0l8.11 4.696c.529.305.854.874.854 1.484v9.371c0 .61-.325 1.179-.854 1.484l-8.11 4.695a2.08 2.08 0 0 1-.854.183Zm2.5-6.444c-3.557 0-4.289-1.627-4.289-3.009 0-.122.102-.244.244-.244h1.057c.122 0 .224.082.224.204.162 1.077.63 1.605 2.784 1.605 1.708 0 2.44-.386 2.44-1.3 0-.53-.204-.915-2.866-1.18-2.216-.223-3.598-.711-3.598-2.48 0-1.646 1.382-2.622 3.7-2.622 2.601 0 3.882.895 4.044 2.846 0 .061-.02.122-.06.183-.041.04-.102.081-.163.081H14.96a.238.238 0 0 1-.224-.183c-.244-1.118-.874-1.483-2.54-1.483-1.87 0-2.095.65-2.095 1.138 0 .59.265.772 2.785 1.097 2.5.326 3.68.793 3.68 2.541-.02 1.79-1.484 2.806-4.066 2.806Z" />
  </svg>
);
export default SvgNodejs;
