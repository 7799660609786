import * as React from 'react';
import { SVGProps } from 'react';

const SvgJava = (
  props: SVGProps<SVGSVGElement> & React.HTMLAttributes<HTMLElement>
) => (
  <svg
    width={18}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M5.804 18.553s-.928.532.66.712c1.925.217 2.909.186 5.03-.21 0 0 .557.345 1.335.643-4.753 2.01-10.759-.116-7.025-1.145ZM5.224 15.93s-1.041.76.549.922c2.055.21 3.679.226 6.488-.307 0 0 .388.388 1 .6-5.749 1.659-12.15.131-8.037-1.215ZM10.12 11.48c1.172 1.331-.307 2.528-.307 2.528s2.974-1.514 1.608-3.411c-1.275-1.769-2.254-2.648 3.043-5.678 0 0-8.314 2.048-4.343 6.562Z" />
    <path d="M16.408 20.493s.687.558-.756.99c-2.744.82-11.421 1.067-13.832.032-.866-.372.759-.887 1.27-.996.533-.114.838-.093.838-.093-.964-.67-6.229 1.315-2.675 1.884 9.693 1.55 17.67-.699 15.155-1.817ZM6.25 13.212s-4.413 1.034-1.562 1.41c1.203.159 3.603.123 5.838-.062 1.826-.152 3.66-.475 3.66-.475s-.644.272-1.11.586c-4.482 1.163-13.14.622-10.647-.568 2.108-1.005 3.822-.891 3.822-.891ZM14.168 17.578c4.556-2.336 2.45-4.58.98-4.278-.361.074-.522.138-.522.138s.134-.207.39-.296c2.908-1.009 5.146 2.976-.94 4.554 0 0 .071-.063.092-.118ZM11.421.025s2.524 2.49-2.393 6.32c-3.943 3.071-.899 4.822-.002 6.823-2.301-2.048-3.99-3.851-2.857-5.53 1.663-2.463 6.27-3.657 5.252-7.613Z" />
    <path d="M6.698 23.908c4.374.276 11.09-.154 11.249-2.195 0 0-.306.774-3.615 1.388-3.732.693-8.336.612-11.067.168 0 0 .56.457 3.433.639Z" />
  </svg>
);
export default SvgJava;
