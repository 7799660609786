import { NullableClassName } from '@squareup/dex-types-shared-ui';
import { CodeField } from '@squareup/dex-ui-shared-base';
import { highlight, HighlightLanguage } from '@squareup/dex-utils-highlight';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';

import styles from './code.module.css';
import highlightStyles from './highlight.module.css';

interface CodeProps {
  code: string;
  language: HighlightLanguage;
  showLineNumbers?: boolean | undefined;
}

const Code: FunctionComponent<CodeProps & NullableClassName> = ({
  className,
  code,
  language,
  showLineNumbers,
}) => {
  const numLines = code?.split('\n').length || 1;

  return (
    <CodeField
      data-testid="code-block-contents"
      data-line-numbers-active={showLineNumbers}
      style={
        {
          '--line-number-length': `${numLines.toString().length}ch`,
        } as React.CSSProperties
      }
      as={'div'}
      className={clsx(
        styles['code'],
        highlightStyles['code-highlight'],
        highlightStyles[language as keyof typeof highlightStyles],
        showLineNumbers && styles['line-numbers'],
        className
      )}
    >
      {highlight(code, language)}
    </CodeField>
  );
};

export { Code };
