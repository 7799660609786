import * as React from 'react';
import { SVGProps } from 'react';

const SvgCsharp = (
  props: SVGProps<SVGSVGElement> & React.HTMLAttributes<HTMLElement>
) => (
  <svg
    width={20}
    height={23}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M19.818 6.486c0-.37-.079-.702-.242-.98a1.849 1.849 0 0 0-.708-.69C16.255 3.31 13.636 1.804 11.022.297c-.708-.405-1.385-.393-2.087.018-1.04.611-6.255 3.6-7.804 4.501-.64.37-.95.938-.95 1.676v9.098c0 .363.08.684.23.962.158.284.4.526.72.714 1.555.901 6.764 3.884 7.804 4.5.702.412 1.385.43 2.087.019 2.614-1.513 5.233-3.013 7.846-4.52.327-.187.563-.423.72-.713.151-.278.23-.599.23-.962V6.486Z" />
    <path
      className="fill-background"
      d="M12.825 12.626a3.251 3.251 0 0 1-2.83 1.658c-1.21 0-2.263-.66-2.82-1.64a3.245 3.245 0 0 1 5.626-3.236l2.837-1.634a6.528 6.528 0 0 0-5.65-3.26 6.52 6.52 0 1 0 .006 13.042 6.525 6.525 0 0 0 5.668-3.29l-2.837-1.64Zm3.588-3.134h-.648v3.128h.648V9.492Zm1.433 0H17.2v3.128h.647V9.492Z"
    />
    <path
      className="fill-background"
      d="M18.372 10.019h-3.127v.647h3.127v-.647Zm0 1.427h-3.127v.648h3.127v-.648Z"
    />
  </svg>
);
export default SvgCsharp;
