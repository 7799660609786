import * as React from 'react';
import { SVGProps } from 'react';

const SvgPython = (
  props: SVGProps<SVGSVGElement> & React.HTMLAttributes<HTMLElement>
) => (
  <svg
    width={22}
    height={21}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M10.878 0c-.843.004-1.649.076-2.357.201-2.088.37-2.467 1.141-2.467 2.565v1.88h4.934v.627H4.202c-1.434 0-2.69.862-3.082 2.502-.453 1.879-.473 3.051 0 5.014.35 1.46 1.188 2.501 2.622 2.501h1.696v-2.254c0-1.628 1.41-3.065 3.083-3.065h4.928c1.371 0 2.466-1.13 2.466-2.507V2.766c0-1.337-1.127-2.341-2.466-2.565a15.39 15.39 0 0 0-2.57-.2ZM8.21 1.512c.51 0 .926.423.926.944a.932.932 0 0 1-.926.937.931.931 0 0 1-.926-.937c0-.52.415-.944.926-.944Z" />
    <path d="M16.53 5.273v2.191c0 1.699-1.44 3.128-3.082 3.128H8.521c-1.35 0-2.467 1.156-2.467 2.508v4.697c0 1.337 1.162 2.124 2.467 2.508 1.561.459 3.059.542 4.927 0 1.243-.36 2.467-1.084 2.467-2.508v-1.88h-4.928v-.627h7.395c1.434 0 1.968-1 2.467-2.501.515-1.545.493-3.032 0-5.014-.354-1.428-1.031-2.502-2.467-2.502h-1.851ZM13.76 17.171c.511 0 .926.419.926.937 0 .52-.415.943-.926.943a.936.936 0 0 1-.926-.943c0-.518.416-.937.926-.937Z" />
  </svg>
);
export default SvgPython;
