// eslint-disable-next-line no-restricted-imports
import { MarketActivityIndicator as MarketActivityIndicatorBinding } from '@market/react';
import clsx from 'clsx';
import React from 'react';

import styles from './market-activity-indicator.module.css';

type MarketActivityIndicatorProps = React.ComponentProps<
  typeof MarketActivityIndicatorBinding
>;

const MarketActivityIndicator = React.forwardRef<
  globalThis.HTMLMarketActivityIndicatorElement,
  MarketActivityIndicatorProps
>(({ children, className, ...rest }, ref) => (
  <MarketActivityIndicatorBinding
    ref={ref}
    {...rest}
    className={clsx(styles['market-activity-indicator'], className)}
  >
    {children}
  </MarketActivityIndicatorBinding>
));

MarketActivityIndicator.displayName = 'MarketActivityIndicator';

export { MarketActivityIndicator };
