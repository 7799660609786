import * as React from 'react';
import { SVGProps } from 'react';

const SvgGo = (
  props: SVGProps<SVGSVGElement> & React.HTMLAttributes<HTMLElement>
) => (
  <svg
    width={36}
    height={25}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.283 11.09c-.068 0-.084-.034-.05-.084l.354-.456c.034-.05.118-.084.185-.084h6.026c.068 0 .084.05.05.1l-.286.44c-.034.05-.118.101-.169.101l-6.11-.017ZM.734 12.643c-.067 0-.084-.034-.05-.085l.354-.455c.034-.05.118-.085.186-.085H8.92c.068 0 .102.051.085.102l-.135.405c-.017.067-.085.101-.152.101l-7.984.017ZM4.819 14.196c-.068 0-.085-.05-.05-.101l.236-.423c.033-.05.1-.1.168-.1H8.55c.068 0 .101.05.101.117l-.033.405c0 .068-.068.119-.119.119l-3.68-.017ZM22.339 10.786c-1.063.27-1.79.473-2.836.743-.253.068-.27.084-.49-.169-.252-.287-.438-.472-.793-.641-1.063-.524-2.092-.372-3.054.253-1.148.743-1.739 1.84-1.722 3.207.017 1.35.945 2.464 2.278 2.65 1.148.152 2.11-.253 2.87-1.114.152-.186.287-.388.456-.625H15.79c-.354 0-.439-.22-.32-.506.219-.523.624-1.401.86-1.84a.456.456 0 0 1 .422-.27h6.144c-.034.456-.034.912-.101 1.367a7.197 7.197 0 0 1-1.384 3.309c-1.216 1.603-2.802 2.599-4.81 2.869-1.655.22-3.19-.101-4.541-1.114-1.25-.945-1.958-2.194-2.144-3.747-.22-1.84.32-3.494 1.435-4.946 1.198-1.57 2.785-2.565 4.726-2.92 1.586-.287 3.106-.1 4.473.827.894.591 1.536 1.401 1.958 2.38.101.152.034.237-.17.287Z"
      fill="#A6ADC2"
    />
    <path
      d="M27.926 20.12c-1.536-.034-2.937-.472-4.119-1.485-.995-.861-1.62-1.958-1.822-3.258-.304-1.907.219-3.595 1.367-5.097 1.232-1.62 2.717-2.464 4.726-2.819 1.721-.304 3.342-.135 4.81.861 1.334.911 2.16 2.144 2.38 3.764.287 2.279-.371 4.135-1.941 5.722-1.114 1.13-2.481 1.84-4.051 2.16-.456.085-.911.102-1.35.152Zm4.017-6.819c-.017-.22-.017-.388-.05-.557-.305-1.67-1.84-2.616-3.444-2.245-1.57.355-2.582 1.35-2.954 2.937-.304 1.317.338 2.65 1.553 3.19.928.405 1.857.355 2.751-.101 1.334-.692 2.06-1.772 2.144-3.224Z"
      fill="#A6ADC2"
    />
  </svg>
);
export default SvgGo;
